
import { Empty } from "antd";
import React  from "react";
const Home =()=>{


    return (
        <div className="homeB">

        <div className="cardInfo">
            <div><img src="/logoopen2be.png" alt="" /></div>
            <div><p>Et deserunt adipisci sed mollitia quia ut placeat velit. Est officia perferendis ut sunt laborum ut fuga amet. Non voluptatem atque est architecto omnis ea voluptates eius et nesciunt atque. Et quasi rerum et vitae laudantium aut neque galisum id perferendis tempore.
</p></div>
        </div>
        </div>
    );
}


export default Home;