import React, { useEffect } from "react";
import { useRecoilState, useRecoilValue } from 'recoil';
import { balance, nameAndSymbol, selectedNetwork, SymbolGovToken, SymbolTreasuryToken, totalSypply, treasuryBalance, Update } from "../atom";
import { boxContractAddress, getBalance, getBalanceTreasury, getTokenNameAndSymbol, getTokenNameAndSymbolTreasury, getTokenSymbol, getTokenSymbolTreasury, getTotalSupply } from "../open2web3";
import { TokenAddressTreasury } from './../open2web3';
import { TreasuryTokenInfo } from './../atom';

function UpdateValuesComponent() {

    const selectedNetworkState = useRecoilValue(selectedNetwork);
    const [balanceState,setBalanceState] = useRecoilState(balance);
    const [totalSypplyState,setTotalSypplyState] = useRecoilState(totalSypply);
    const [nameAndSymbolState ,setNameAndSymbolState]= useRecoilState(nameAndSymbol);
    const [symbolGovTokenState,setSymbolGovTokenState] = useRecoilState(SymbolGovToken);
    const [symbolTreasuryTokenState,setSymbolTreasuryTokenState] = useRecoilState(SymbolTreasuryToken);
    const [treasuryTokenInfoState,setTreasuryTokenInfoState] = useRecoilState(TreasuryTokenInfo);
    const [treasuryBalanceState,setTreasuryBalanceState] = useRecoilState(treasuryBalance);
    const [updateState , setUpdateState] = useRecoilState(Update);


    useEffect(()=>{
        getTokenNameAndSymbolHandler()
        getTokenSymnolHandler()
        getTotalSupplyHandler()
        getConnectedUserBalance()
        getTreasuryTokenSymbol()
        getTreasuryBalance()
        setUpdateState(updateState+1)

    },[selectedNetworkState])

    const getTokenNameAndSymbolHandler = async ()=>{
        try{

            const nameAndSymbol = await getTokenNameAndSymbol()
            setNameAndSymbolState(nameAndSymbol)
            // console.log('symbol ', nameAndSymbol);
          }catch(err){
              console.log(err);
          }  
      }

      const getTokenSymnolHandler = async ()=>{
        const symbol = await getTokenSymbol();
        setSymbolGovTokenState(symbol);
      }

      const getTotalSupplyHandler = async ()=>{
        try{
    
          const supply = await getTotalSupply()
          setTotalSypplyState(supply);
           
        }catch(err){
            console.log(err);
        }
    }

    const getConnectedUserBalance = async(connectedAddress) => {
        try {
            const bal =await getBalance(connectedAddress);
            setBalanceState(bal);
        } catch (err) {
          console.log(err);
        }
      }

      const getTreasuryTokenSymbol = async ()=>{
        const symbol = await getTokenSymbolTreasury();
        console.log('sym here',symbol);
       setSymbolTreasuryTokenState(symbol);
    }

    const getTreasuryBalance = async ()=>{

        const res = await getBalanceTreasury(boxContractAddress) ; 
        // console.log('ress' ,res);
        setTreasuryBalanceState(res);
       }

    const getTreasuryTokenInfo = async ()=>{

        const res  = await getTokenNameAndSymbolTreasury(TokenAddressTreasury);
        setTreasuryTokenInfoState(res)
       }



    useEffect(()=>{

    },[selectedNetworkState])


    return ( 
    <>
    </> 
    );
}

export default UpdateValuesComponent;