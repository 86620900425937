import React, { Component } from 'react';
import { Menu, Dropdown } from 'antd';
import { useRecoilState } from 'recoil';
import { connected } from '../atom';
import {PlusOutlined, MinusOutlined ,PlusSquareOutlined} from '@ant-design/icons';

function InfoCard(props) {
    const [connectedAddress , setConnectedAddress] = useRecoilState(connected);
    function handleButtonClick(e) {
    
      // console.log('click left button', e);
    }
    
    function handleMenuClick(e) {
   
      // console.log('click', e);
    }
    const menu = (
      <Menu
        onClick={handleMenuClick}
        items={[
          {
            label: 'Add tokens',
            key: '1',
            icon:<PlusOutlined />,
          },
          {
            label: 'Remove tokens',
            key: '2',
            icon:<MinusOutlined />,
          },
          {
            label: 'Add custom label',
            key: '3',
            icon:<PlusSquareOutlined />,
          },
        ]}
      />
    );
    return (
      <div className="infoCardD">
       
        
   <div><h6>{props.title}</h6></div>    
  
    {/* <Card.Subtitle className="mb-2 text-muted">Card Subtitle</Card.Subtitle> */}

      <div className="alignChoix  walletm"><span>{props.text}</span>
  <div className={props.displayD}><Dropdown.Button onClick={handleButtonClick} overlay={menu}>
</Dropdown.Button>
</div>
      
      
      </div>
      </div>
       
  
    

     );
}

export default InfoCard;