function HistoryBoxStatus(props) {
    return ( <>
 
          
      
          <div className="statusH">
        {/* <img src={props.icon} />
        <div>
          <span>{props.label}</span><br />
          <small>{props.date}</small>
          </div>
          <img src="/iconLink.svg" /> */}

         <h6 className=" mt-4">Coming soon.. </h6>
          
          </div>
           
        
            
     

     

                 
          
    </> );
}

export default HistoryBoxStatus;