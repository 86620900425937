import axios from "axios";
import { pinataApiKey, pinataApiSecret } from "./open2web3";
// const fs = require('fs');
// import  fs from 'fs';
const FormData = require('form-data');


export const pinFileToIPFS = (data) => {

    const file = new File(["foo"], "foo.txt", {
        type: "text/plain",
      });
    const url = `https://api.pinata.cloud/pinning/pinFileToIPFS`;

    //we gather a local file for this example, but any valid readStream source will work here.
    // let data = new FormData();
    // data.append('file', file);

    //You'll need to make sure that the metadata is in the form of a JSON object that's been convered to a string
    //metadata is optional
    // console.log('data file ',data );
    const metadata = JSON.stringify({
        name: data.get("fileName")
        // keyvalues: {
        //     exampleKey: 'exampleValue'
        // }
    });
    data.append('pinataMetadata', metadata);

    //pinataOptions are optional
    const pinataOptions = JSON.stringify({
        cidVersion: 0,
        customPinPolicy: {
            regions: [
                {
                    id: 'FRA1',
                    desiredReplicationCount: 1
                },
                {
                    id: 'NYC1',
                    desiredReplicationCount: 2
                }
            ]
        }
     });
    // data.append('pinataOptions', pinataOptions);

    return axios
        .post(url, data, {
            maxBodyLength: 'Infinity', //this is needed to prevent axios from erroring out with large files
            headers: {
                'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
                pinata_api_key: pinataApiKey,
                pinata_secret_api_key: pinataApiSecret
            }
        })
        .then(function (response) {
            return response;
        })
        .catch(function (error) {
            //handle error here
        });
};
 