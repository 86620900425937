import { atom } from "recoil";
export const connected = atom({
    key :"connectedAddress",
    default : ""
    // localStorage.getItem('lastConnectedAddress')!=null ? localStorage.getItem('lastConnectedAddress') : ""
});

export const balance = atom({
    key : "balanceConnectedUser",
    default:''
})

export const totalSypply = atom({
    key : "totalSypply",
    default:''
})

export const nameAndSymbol = atom({
    key : "nameAndSymbol",
    default:''
})

export const SymbolGovToken = atom({
    key : "symbolGovToken",
    default:''
})

export const SymbolTreasuryToken = atom({
    key : "symbolTreasury",
    default:''
})
export const TreasuryTokenInfo = atom({
    key : "treasuryTokenInfo",
    default:''
})
export const treasuryBalance = atom({
    key : "treasuryBalance",
    default:''
})

export const Update = atom({
    key : "update",
    default: 1
})

export const selectedNetwork = atom({
    key : "selectedNetwork",
    default: ""
})