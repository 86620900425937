import Web3 from "web3";
import { Button, Modal } from 'antd';
import { useState } from 'react';
import VotersList from "./VotersList";
import { useEffect } from 'react';

function TopVoters(props) {
    const web3 = new Web3(Web3.givenProvider);
    const [openVotersList , setOpenVotersList] = useState(false);

    const showModal = () => {
      setOpenVotersList(true);
    };
  
    const handleOk = () => {
      setOpenVotersList(false);
    };
  
    const handleCancel = () => {
      setOpenVotersList(false);
    };

    useEffect(()=>{
      // console.log('modal ',openVotersList)
    },[openVotersList])

  return (
    <>

      
        

      <div className="tokenInfoD">
        <small className="voteAdress">{props.address}</small><br/>

        <small>{web3.utils.fromWei(
              props.weight  ,
            "ether"
          )}</small>

          
        
         
        
      
          
      </div>
      
    </>
  );
}

export default TopVoters;
