import React from 'react';
import { Menu, Button, Alert,Layout } from 'antd';
import {
 
  AppstoreOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  PieChartOutlined,
  DesktopOutlined,
  ContainerOutlined,
  MailOutlined
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';

function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
}

const items = [
  getItem(<Link to="/tokens">Tokens</Link>, '1', <PieChartOutlined />),
  getItem(<Link to="/vote">Vote</Link>, '2', <DesktopOutlined />),
  getItem(<Link to="/finance">Finance</Link>, '3', <ContainerOutlined />),
  
];
const { Header, Content, Footer, Sider } = Layout;
const LeftNav = () => {
  const [collapsed, setCollapsed] = React.useState(false);
  const [selectedItem ,setSelectedItem]=React.useState(localStorage.getItem('currentItem'))
  const currentPage = useLocation()

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  const setPage=(currentItem)=>{
    let item = localStorage.getItem('currentItem');
    if(item==null){
      localStorage.setItem("currentItem" ,"1")
    }else{
      localStorage.setItem("currentItem" ,currentItem)
      setSelectedItem(item)
    }
  }

  useEffect(()=>{
    console.log(currentPage.pathname)

    if(currentPage.pathname=='/tokens'){
      setPage("1")
    }

    if(currentPage.pathname=='/vote' || currentPage.pathname.includes('/proposal') ){
      setPage("2")
    }

    if(currentPage.pathname=='/finance'){
      setPage("3")
    }
 
  },[currentPage])

  return (
     
      <Sider collapsible collapsed={collapsed} onCollapse={toggleCollapsed}  breakpoint="lg" collapsedWidth="30">
 <div className="logo" >
<Menu theme="dark" defaultSelectedKeys={[selectedItem]} mode="inline" items={items} />
</div>
</Sider>
       
     
  );
};

export default LeftNav;