import { useState } from 'react';
import { getIpfsDocumentByProposalId, ipfsBaseLink } from '../../open2web3';
import { useEffect } from 'react';
function DetailsBox(props) {

  const [ipfsDoc , setIpfsDoc] =useState(null)


  const getIpfsDocument = async () => {
    const res = await getIpfsDocumentByProposalId(props.proposalId);
    if (res.ipfsDocument != "") {
      setIpfsDoc(ipfsBaseLink + res.ipfsDocument);
    }
    
  };

  useEffect(()=>{
    if(props.proposalId!=null && props.proposalId!=undefined)
    getIpfsDocument();
  },[props.proposalId])

  useEffect(()=>{
    // console.log('iii ', ipfsDoc)
    // console.log('iii ', props.proposalId)
    
  },[ipfsDoc])


  return (
    <div className="proposalCardG voteBg">
      <h6>Details</h6>
   
{ipfsDoc!=null && <iframe src={ipfsDoc} style={{height:1000}}></iframe>}
          
    
    </div>
  );
}

export default DetailsBox;
