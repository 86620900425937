import { ApolloClient, gql, InMemoryCache } from "@apollo/client";
import { Modal, Pagination } from "antd";
import { graphAPIGov } from "../../open2web3";
import { useState, useEffect } from 'react';
import Web3 from "web3";

function VotersList(props) {
  const web3 = new Web3(Web3.givenProvider);

    const [votes , setVotes] = useState([])
    const [page , setPage] = useState(1)
    const perPage=5

    const client = new ApolloClient({
        uri: graphAPIGov,
        cache: new InMemoryCache(),
      });

      const getVotersPage = async (page) => {
        // console.log("trying to fetch votes")
        client
          .query({
            query: gql`{
                  votes( where : {proposalId: "${props.proposalId}" support:"${props.voteState}" } first : ${perPage} , skip: ${perPage * (page -1) } ){
                    id
                    proposalId
                    voter
                    support
                    weight
                    date
                  }
                }`,
          })
          .then((result) => setVotes(result.data.votes));
      };

      useEffect(()=>{
        if(props.open===true){

          getVotersPage(1)
        }
        
      },[props.open])

      const onPageChange=(page)=>{
        setPage(page);
        getVotersPage(page)
      }

    
    return ( 

        <>
        <Modal title={props.voteTitle} visible={props.open} footer={false} onOk={props.handleOk} onCancel={props.handleCancel}>
         {
            votes.map((vote) =>
            <li className="tokenInfoD pb-3"><span>{vote.voter}</span> <span style={{color:'#0D3885',fontWeight:600}}>{((web3.utils.fromWei(vote.weight,"ether")))}</span></li>
          )
         }
         <Pagination current={page} pageSize={perPage} total={props.total} onChange={onPageChange} />
      </Modal>
        </>
     );
}

export default VotersList;